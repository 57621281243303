import React, { useEffect, useState } from "react";
// import chart from "../assests/images/icon-chart.svg";
import bnbIcon from "../assests/images/icon-bnb.svg";
import pinksaleIcon from "../assests/images/icon-pinksale.svg";
import coinmarketcapIcon from "../assests/images/icon-coinmarketcap.svg";
import dexview_logo from "../assests/images/dexview_logo.png";
import pancakeswapIcon from "../assests/images/icon-pancakeswap.svg";
import coinsultIcon from "../assests/images/icon-coinsult.svg";
import aiImg1 from "../assests/images/heading1.png";
import aiImg2 from "../assests/images/heading2.png";
import aiImg4 from "../assests/images/icon-ai-img4.svg";
import roadmap from "../assests/images/roadmap.svg";
import roadmapmob from "../assests/images/roadmap_mob.svg";
// import sparkleIcon1 from "../assests/images/icon-sparkle1.svg";
import sparkleIcon2 from "../assests/images/icon-sparkle2.svg";
import sparkleIcon3 from "../assests/images/icon-sparkle3.svg";
import sparkleIcon4 from "../assests/images/icon-sparkle4.svg";
import sparkleIcon5 from "../assests/images/icon-sparkle5.svg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./style.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CircularProgressBar from "../CircularProgressBar";

export default function Home() {
  let [isMobile, setisMobile] = useState(false);
  const [iscopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText("0xc1012AF7BE06C250f7Ebd21392c169acC64feC95");
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  function redirectToNewTab(url) {
    window.open(url, "_blank");
  }

  useEffect(() => {
    setisMobile(window.innerWidth < 800);
    AOS.init({ duration: 2000 });
  }, []);
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img src={bnbIcon} className="bnbIcon" alt="binance-icon" onDragStart={handleDragStart} />,
    <img src={pinksaleIcon} onClick={() => redirectToNewTab("https://www.pinksale.finance/launchpad/0xcD5C04817E9F07090E71b20275cBe31561160E0F?chain=BSC")} alt="pinksale-icon" onDragStart={handleDragStart} />,
    <img src={coinmarketcapIcon} className="coinmarketcapIcon" alt="coinmarketcap-icon" onDragStart={handleDragStart} />,
    <img src={pancakeswapIcon} onClick={() => redirectToNewTab("https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0xc1012AF7BE06C250f7Ebd21392c169acC64feC95")} className="pancakeswapIcon" alt="pancakeswap-icon" onDragStart={handleDragStart} />,
    <img src={dexview_logo} onClick={() => redirectToNewTab("https://www.dexview.com/bsc/0xc1012AF7BE06C250f7Ebd21392c169acC64feC95")} className="dexviewIcon" alt="dexview-icon" onDragStart={handleDragStart} />,
    <img src={coinsultIcon} onClick={() => redirectToNewTab("https://coinsult.net/projects/yooshiape/")} className="coinsultIcon" alt="coinsult-icon" onDragStart={handleDragStart} />,
  ];

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1200: { items: 3 },
    1500: { items: 5 },
  };

  return (
    <>
      <div className="backdropDiv1">
        <div className="eclipseDiv"></div>
        <div id="home" className="mainWrapper1 faders" data-aos="fade-up">
          <div className="titleContent">
            <div>
              <div className="mainHeadingTxt">
                Introducing
                <br />
                <div className="gradientTxt">YooshiApe</div>
                Mighty
              </div>
            </div>
            <div className="titleInfo">Meet Yooshi Ape! It's a meme token on Binance Smart Chain. We made it with the help of our friends and fans. We love memes, and Yooshi is all about that. We believe you will love it too!.</div>
            <div className="btn1" onClick={() => redirectToNewTab("https://www.dexview.com/bsc/0xc1012AF7BE06C250f7Ebd21392c169acC64feC95")}>
            Dexview Link
            </div>
            <div className="tokenomics-box address-box">
              <>
                <div className="copy">Token Address :{iscopied ? <i className="fas fa-check"></i> : <i onClick={handleCopyClick} className="far fa-copy"></i>}</div>
                <div className="address button-text">
                  <span>0xc1012AF7BE06C250f7Ebd21392c169acC64feC95 </span>
                </div>
              </>
            </div>
          </div>
          <img src={aiImg1} alt="ai-img" className="float-img" />
          <img className="aiImg4" src={aiImg4} alt="ai-img" />
        </div>
      </div>

      <div className="backdropDiv2">
        <div className="mainWrapper2 faders">
          <div className="binanceDiv">
            <AliceCarousel items={items} responsive={responsive} autoPlay autoPlayInterval={1000} animationDuration={1000} disableButtonsControls disableDotsControls mouseTracking infinite animationType="fadeout" paddingLeft={24} paddingRight={24} itemPosition="center" stagePadding={{ paddingLeft: 60, paddingRight: 60 }} slideToIndex={0} dotsDisabled buttonsDisabled />
          </div>
          <div id="about" className="aboutWrapper faders" data-aos="fade-up">
            <img src={aiImg2} alt="ai-img" />
            <div className="aboutDiv">
              <div>
                <div className="aboutTitle">About us</div>
                <div className="aboutMainTxtheading">Unleashing Meme Magic with Yooshi's Hype</div>
              </div>
              <div className="aboutMainTxt1">Yooshi Ape: Embrace the meme revolution! We're more than just a crypto token; we're inspired by memes and the vibrant energy they bring. Embark on our unique journey where memes and profit collide.</div>
              <div className="aboutMainTxt2"> Our mission is to amplify Yooshi Ape, highlighting its distinct character, and create rewarding opportunities for meme enthusiasts. Dive in and be part of the Yooshi Ape experience!</div>
              <img className="sparkleIcon4" src={sparkleIcon4} alt="sparkle" />
            </div>
          </div>
          <div className="goalWrapper faders" data-aos="fade-up">
            {/* <div className='eclipseDiv'></div> */}
            <div>
              <img className="sparkleIcon5" src={sparkleIcon5} alt="sparkle" />
              <img className="sparkleIcon3" src={sparkleIcon3} alt="sparkle" />
              <div className="aboutTitle">The goal is to</div>
              <div className="aboutMainTxtheading">Elevate the Yooshi Hype for Profit and Thrills!</div>
            </div>
            <div className="aboutMainTxt1">Yooshi Ape's mission is simple yet electrifying: harness the Yooshi craze to bring profit and excitement to meme enthusiasts. We're not just riding the Yooshi hype; we're turbocharging it. Join us on this exhilarating adventure, where memes turn into profits, and the original Yooshi shines brighter than ever before!</div>
            <img className="sparkleIcon2" src={sparkleIcon2} alt="sparkle" />
          </div>
          <div id="tokenomics" className="tokenomicsWrapper faders" data-aos="fade-up">
            <div className="tokenomics">
              <div className="tokenomicsFeatures">
                <div className="headingUnderlineWrapper">
                  <div className="tokenomicsTxt">Tokenomics</div>
                </div>
                <div className="tokenomics-div">
                  <div>
                    <div className="tokenomics-box">
                      <li>
                        Token Name : <span>YooshiApe</span>
                      </li>
                      <li>
                        Token Symbol: <span>YooshiApe</span>
                      </li>
                      <li>
                        Total Supply: <span>1B (1,000,000,000)</span>
                      </li>
                      <li>
                        Decimals: <span>18</span>
                      </li>
                    </div>
                    <br />
                    <div className="tokenomics-box">
                      <li>
                        Blockchain: <span>Binance Smart Chain (BEP-20)</span>
                      </li>
                      <li>
                        Tokens for fairlaunch: <span>500m</span>
                      </li>
                      <li>
                        Tokens for liquidity: <span>242m (ratio: 51%)</span>
                      </li>
                      <li>
                        Initial burn: <span>258m</span>
                      </li>
                      <li>
                        Token tax @buy: <span>3% Tax</span>
                      </li>
                      &nbsp;&nbsp;&nbsp;(1% usdt reflection, 2% marketing )
                      <li>
                        Token tax @sell: <span>3% Tax</span>
                      </li>
                      &nbsp;&nbsp;&nbsp;(1% usdt reflection, 2% marketing )
                    </div>
                  </div>
                  <div className="progress-bar-div">
                    <div className="progress-bar-align">
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={51} circleWidth={"150"} color="#48c63f" className="loader" />
                          <h1>Fair Launch Tokens</h1>
                        </div>
                      </div>
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={25} circleWidth={"150"} color="#48c63f" />
                          <h1>Liquidity</h1>
                        </div>
                      </div>
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={24} circleWidth={"150"} color="#48c63f" />
                          <h1>Initial Burn</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="roadmap" className=" faders" data-aos="fade-up">
            <div className="roadmapimg">{isMobile ? <img src={roadmapmob} alt="roadmap" /> : <img src={roadmap} alt="roadmap" />}</div>
          </div>
        </div>
      </div>
    </>
  );
}
